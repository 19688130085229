var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Ingrese sector: ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.excelsector),expression:"excelsector"}],staticClass:"form-control",attrs:{"type":"text","aria-describedby":"helpId","placeholder":""},domProps:{"value":(_vm.excelsector)},on:{"input":function($event){if($event.target.composing)return;_vm.excelsector=$event.target.value}}}),_c('small',{staticClass:"form-text text-muted",attrs:{"id":"helpId"}},[_vm._v("se buscarán todos los bultos en la campaña "+_vm._s(_vm.campaniaSelected))])])]),_c('div',{staticClass:"col-md-1"},[_c('download-excel',{staticClass:"btn btn-sm",staticStyle:{"cursor":"pointer","margin-top":"31px"},attrs:{"fields":_vm.columnasEXCLsector,"before-generate":() => {
          return _vm.startDownload();
        },"fetch":() => {
          return _vm.fnGenerarExcelPorSector(_vm.excelsector);
        },"type":_vm.dataExportType,"before-finish":_vm.finishDownload,"name":'Bultos del sector ' + _vm.excelsector + _vm.dataExportType}},[_vm._v("Excel")])],1),_c('div',{staticClass:"col-md-4"},[_c('label',{attrs:{"for":""}},[_vm._v("Consultando pedidos de la campaña:")]),_c('select',{staticClass:"form-control",attrs:{"name":"campania"},on:{"change":_vm.fnSelecionarCampania}},_vm._l((_vm.campanias),function(item,index){return _c('option',{key:index,domProps:{"selected":index == 0,"value":item.campania}},[_vm._v(" Campaña "+_vm._s(item.campania)+" ")])}),0)]),_c('div',{staticClass:"col-md-12"},[_c('v-server-table',{ref:"tblPedidosBultosSector",attrs:{"columns":_vm.columns,"options":_vm.optionsVTable},scopedSlots:_vm._u([{key:"descargar",fn:function(props){return _c('div',{},[_c('download-excel',{staticClass:"btn btn-sm",staticStyle:{"cursor":"pointer"},attrs:{"fields":_vm.columnasEXCLsector,"before-generate":() => {
              return _vm.startDownload();
            },"fetch":() => {
              return _vm.fnGenerarExcel(props.row.ROUTE_ID);
            },"type":_vm.dataExportType,"before-finish":_vm.finishDownload,"name":'Bultos de la ruta ' + props.row.ROUTE_ID + _vm.dataExportType}},[_vm._v("Excel")])],1)}},{key:"ultimo_escaneado_fecha",fn:function(props){return _c('span',{},[_vm._v(" "+_vm._s(_vm._f("moment")(props.row.ultimo_escaneado_fecha,"utc", "DD/MM/YYYY HH:mm:ss a"))+" ")])}},{key:"escaneados",fn:function(props){return _c('span',{staticStyle:{"cursor":"pointer"},attrs:{"data-toggle":"modal","data-target":"#modalBultosPendientes"},on:{"click":function($event){return _vm.fnMostrarBultosSinValidar('Comple', props.row.ROUTE_ID)}}},[_vm._v(" "+_vm._s(props.row.escaneados)+" ")])}},{key:"pendientes",fn:function(props){return _c('span',{staticStyle:{"cursor":"pointer"},attrs:{"data-toggle":"modal","data-target":"#modalBultosPendientes"},on:{"click":function($event){return _vm.fnMostrarBultosSinValidar('Sin', props.row.ROUTE_ID)}}},[_vm._v(" "+_vm._s(props.row.pendientes)+" ")])}},{key:"porcentaje",fn:function(props){return _c('div',{staticClass:"progress"},[_c('div',{class:'progress-bar ' + _vm.getColor(props.row.porcentaje),style:('width:' + props.row.porcentaje + '%'),attrs:{"role":"progressbar","aria-valuenow":"25","aria-valuemin":"0","aria-valuemax":"100"}}),_c('small',{class:'justify-content-center d-flex position-absolute progress-value ' +
              _vm.getTextColor(props.row.porcentaje)},[_vm._v(" "+_vm._s(((props.row.escaneados / props.row.total) * 100).toFixed(0))+"% ")])])}}])})],1),_c('modalBultosPendientes',{attrs:{"fk_estadoDePedido":_vm.fk_estadoDePedido,"fk_ROUTE":_vm.fk_ROUTE,"campaniaSelected":_vm.campaniaSelected}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('h3',[_vm._v("Reporte de bultos escaneados por sector")])])
}]

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-12"},[(_vm.flag_filtradoDePlacaActivo)?_c('download-excel',{staticClass:"outline-btn-excel pull-right",staticStyle:{"cursor":"pointer","margin-bottom":"7px"},attrs:{"fields":_vm.columnasEXCLsector,"before-generate":() => {
          return _vm.startDownload();
        },"fetch":() => {
          return _vm.fnGenerarExcelReportesAcumulado();
        },"type":_vm.dataExportType,"before-finish":_vm.finishDownload,"name":'Multiples reportes de bultos escaneados (' +
          _vm.flag_nombre_piloto_filtro +
          ') ' +
          _vm.dataExportType}},[_vm._v("Descargar detalle de bultos de reportes filtrados")]):_vm._e()],1),_c('div',{staticClass:"col-md-12"},[_c('v-server-table',{ref:"tblReporteDeBultosValidados",attrs:{"columns":_vm.columns,"options":_vm.optionsVTable},scopedSlots:_vm._u([{key:"descargar",fn:function(props){return _c('div',{},[_c('download-excel',{staticClass:"btn btn-sm",staticStyle:{"cursor":"pointer"},attrs:{"fields":_vm.columnasEXCLsector,"before-generate":() => {
              return _vm.startDownload(props.row.id);
            },"fetch":() => {
              return _vm.fnGenerarExcel(props.row.id);
            },"type":_vm.dataExportType,"before-finish":_vm.finishDownload,"name":'Bultos del reporte con id ' + props.row.id + _vm.dataExportType}},[_vm._v("Excel")])],1)}},{key:"ultimo_escaneado_fecha",fn:function(props){return _c('span',{},[_vm._v(" "+_vm._s(_vm._f("moment")(props.row.ultimo_escaneado_fecha,"utc", "DD/MM/YYYY HH:mm:ss a"))+" ")])}},{key:"bultos_escaneados",fn:function(props){return _c('span',{staticStyle:{"cursor":"pointer"},attrs:{"data-toggle":"modal","data-target":"#modalBultosEscaneados"},on:{"click":function($event){return _vm.fnCargarBultosEscaneados(props.row.id)}}},[_vm._v(" "+_vm._s(props.row.bultos_escaneados)+" ")])}},{key:"bultos_pendientes",fn:function(props){return _c('span',{staticStyle:{"cursor":"pointer"},attrs:{"data-toggle":"modal","data-target":"#modalBultosPendientes"},on:{"click":function($event){return _vm.fnMostrarBultosSinValidar(props.row.id)}}},[_vm._v(" "+_vm._s(props.row.bultos_pendientes)+" ")])}},{key:"porcentaje",fn:function(props){return _c('div',{staticClass:"progress"},[_c('div',{class:'progress-bar ' + _vm.getColor(props.row.porcentaje),style:('width:' + props.row.porcentaje + '%'),attrs:{"role":"progressbar","aria-valuenow":"25","aria-valuemin":"0","aria-valuemax":"100"}}),_c('small',{class:'justify-content-center d-flex position-absolute progress-value ' +
              _vm.getTextColor(props.row.porcentaje)},[_vm._v(_vm._s(props.row.porcentaje)+"%")])])}}])})],1),_c('modalBultosEscaneados',{attrs:{"escaneados_reportebultosvalidados_id":_vm.escaneados_reportebultosvalidados_id}}),_c('modalBultosPendientes',{attrs:{"pendientes_reportebultosvalidados_id":_vm.pendientes_reportebultosvalidados_id}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('h3',[_vm._v("Reporte de pedidos escaneados por cuadrador")])])
}]

export { render, staticRenderFns }
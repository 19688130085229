<template>
  <div>
    <ReportePorCuadrador
      v-if="this.$store.state.bultosPaginado.pageNumber == 2"
    ></ReportePorCuadrador>
    <ReportePorSector
      v-if="this.$store.state.bultosPaginado.pageNumber == 1"
    ></ReportePorSector>
  </div>
</template>
<script>
import ReportePorCuadrador from "@/pages/DashboardEntregas/bultos/ResumenValidacionDeBultos.vue";
import ReportePorSector from "@/pages/DashboardEntregas/bultos/ResumenValidacionDeBultosPorSector.vue";
export default {
  components: {
    ReportePorCuadrador,
    ReportePorSector
  },
  data() {
    return {
      page: 1
    };
  }
};
</script>

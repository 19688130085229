<template>
  <div
    class="modal fade"
    id="modalBultosEscaneados"
    ref="modalBultosEscaneados"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div
      class="modal-dialog"
      role="document"
      style="max-width: 1215px; overflow: auto; overflow-y: auto"
    >
      <div class="modal-content" style="width: 1215px">
        <div class="modal-header">
          <h5 class="modal-title">
            Bultos validados
          </h5>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <v-server-table
                v-if="escaneados_reportebultosvalidados_id != null"
                :columns="columnasEscaneadosTbl"
                :options="opBultosEscaneadosTbl"
                ref="tblBultosValidados"
              >
                <span slot="CREATED" slot-scope="props">
                  {{
                    props.row.CREATED | moment("utc", "DD/MM/YYYY HH:mm:ss a")
                  }}
                </span>
                <div slot="child_row" slot-scope="props">
                  <!-- INICIO TABLA -->
                  <div class="table-full-width table-responsive">
                    <table
                      class=" tablesorter"
                      title="Table on Plain Background"
                      style="background-color: #ffffff !mportant; width: 100%;"
                    >
                      <thead class="" :ref="'thead' + props.row.ERP_DOC">
                        <tr style="background-color:#02144A; color:white;">
                          <td>Box id</td>
                          <td>Cuadrador</td>
                          <td>Fecha picking</td>
                          <td>Bulto</td>
                          <td>Estado</td>
                          <td>Tipo validación</td>
                          <td>Fecha validación</td>
                        </tr>
                      </thead>
                      <tbody class="" :ref="'tbody' + props.row.ERP_DOC">
                        <tr
                          v-for="(item, index) in props.row.bultos.data"
                          :key="index"
                        >
                          <td>{{ item.BOX_ID }}</td>
                          <td>{{ item.cuadrador }}</td>
                          <td>
                            {{
                              item.CREATED
                                | moment("utc", "DD/MM/YYYY HH:mm:ss a")
                            }}
                          </td>
                          <td>
                            <span v-if="item.IS_HOME == 1">Hogar</span>
                            <span v-if="item.IS_PRIZE == 1">Premio</span>
                            <span v-if="item.IS_HOME == 0 && item.IS_PRIZE == 0"
                              >Caja</span
                            >
                          </td>
                          <td
                            :style="[
                              item.estado == 'sin validar'
                                ? { color: 'red' }
                                : { color: 'green' }
                            ]"
                          >
                            {{ item.estado }}
                          </td>
                          <td>{{ item.tipo_validacion }}</td>
                          <td>
                            {{
                              item.fecha_validacion
                                | moment("utc", "DD/MM/YYYY HH:mm:ss a")
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- FIN TABLA -->
                </div>
                <span slot="fecha_validacion" slot-scope="props">
                  {{
                    props.row.fecha_validacion
                      | moment("utc", "DD/MM/YYYY HH:mm:ss a")
                  }}
                </span>
              </v-server-table>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary btn-sm btn-float-right"
            data-dismiss="modal"
          >
            Cerrar ventana
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import axios from "axios";
export default {
  name: "modalBultosPendientes",
  props: ["escaneados_reportebultosvalidados_id"],
  data() {
    return {
      columnasEscaneadosTbl: [
        "supervisor_de_carga",
        "ERP_DOC",
        "STATUSTEXT",
        "DELIVERY_CLIENT_REGION",
        "ROUTE_ID",
        "MANIFEST_DB_ID",
        "validacion_tipo",
        "validacion_estado"
      ],
      opBultosEscaneadosTbl: {
        orderBy: {
          column: "ERP_DOC",
          ascending: true
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down"
        },
        filterByColumn: true,
        idDelReporte: 1,
        perPage: 10,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "Ultimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "No se encontraron resultados...",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas"
        },
        dateFormat: "DD-MM-YYYY HH:mm",
        dateColumns: ["CREATED", "fecha_validacion"],
        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD/MM/YYYY",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre"
            ],
            firstDay: 1
          },
          showDropdowns: true,
          autoUpdateInput: true
        },
        pagination: { chunk: 10, dropdown: false },
        filterable: ["ERP_DOC", "DELIVERY_CLIENT_REGION", "ROUTE_ID"],
        headings: {
          ROUTE_ID: "Ruta",
          supervisor_de_carga: "Cuadrador",
          ERP_DOC: "Pedido",
          STATUSTEXT: "Estado",
          DELIVERY_CLIENT_REGION: "Sector",
          MANIFEST_DB_ID: "ID manifiesto",
          validacion_tipo: "Carga",
          validacion_estado: "Estado"
        },
        requestAdapter(data) {
          let limit = `"$limit":${this.perPage}`;
          let skip = `"$skip":${(data.page - 1) * this.perPage}`;
          let sort = `"$sort[${data.orderBy}]":${data.ascending}`;
          let search = ``;
          search += `, "reportebultosvalidados_id": "${this.idDelReporte}"`;

          search += `, "$or[0][validacion_estado][$like]": "Compl%"`;
          search += `, "$or[1][validacion_estado][$like]": "Validados%"`;
          if (data.query) {
            if (data.query["ERP_DOC"]) {
              search += `, "ERP_DOC[$like]": "%${data.query["ERP_DOC"]}%"`;
            }
            if (data.query["DELIVERY_CLIENT_REGION"]) {
              search += `, "DELIVERY_CLIENT_REGION[$like]": "%${data.query["DELIVERY_CLIENT_REGION"]}%"`;
            }
            if (data.query["ROUTE_ID"]) {
              search += `, "ROUTE_ID[$like]": "%${data.query["ROUTE_ID"]}%"`;
            }
            // if (data.query["id_rol"]) {
            //   search += `, "id_rol": ${data.query["id_rol"]}`;
            // }
            if (data.query["CREATED"]) {
              search += `, "CREATED[$gte]": "${data.query.CREATED.start}"`;
              search += `, "CREATED[$lte]": "${data.query.CREATED.end}"`;
            }
            if (data.query["fecha_validacion"]) {
              search += `, "fecha_validacion[$gte]": "${data.query.fecha_validacion.start}"`;
              search += `, "fecha_validacion[$lte]": "${data.query.fecha_validacion.end}"`;
            }
          }

          return JSON.parse(`{${limit}, ${skip}, ${sort} ${search}}`);
        },
        requestFunction(data) {
          let url = `${process.env.VUE_APP_API_URL}/pedidos`;
          return axios.get(url, {
            params: data,
            headers: {
              Authorization:
                "Bearer  " + window.localStorage.getItem("feathers-jwt")
            }
          });
        },
        responseAdapter(resp) {
          let pedidos = resp.data.data;

          for (let index = 0; index < pedidos.length; index++) {
            let url = `${process.env.VUE_APP_API_URL}/pedidos-detalle?ERP_DOC=${pedidos[index].ERP_DOC}`;
            axios
              .get(url, {
                params: {},
                headers: {
                  Authorization:
                    "Bearer  " + window.localStorage.getItem("feathers-jwt")
                }
              })
              .then(result => {
                console.log(result.data);
                pedidos[index].bultos = result.data;
              });
          }
          console.log(pedidos);
          return {
            data: pedidos,
            count: resp.data.total
          };
        }
      }
    };
  },
  methods: {
    ...mapActions("PedidosDetalle", {
      getPedidoDetalle: "get",
      findPedidoDetalles: "find"
    }),
    fnShowBultosMissing(ERPDOC) {
      this.findPedidoDetalles({ query: { ERP_DOC: ERPDOC } }).then(result => {
        console.log(result);
      });
    }
  },
  mounted() {},
  watch: {
    escaneados_reportebultosvalidados_id: function(newVal, oldVal) {
      this.opBultosEscaneadosTbl.idDelReporte = newVal;
      if (oldVal != null) {
        this.$refs.tblBultosValidados.refresh();
      }
    }
  }
};
</script>
<style>
.VueTables__child-row-toggler--closed::before {
  content: "+";
  color: green;
}

.VueTables__child-row-toggler--open::before {
  content: "-";
  color: red;
}
</style>

<template>
  <div class="row">
    <div class="col-md-12">
      <h3>Reporte de pedidos escaneados por cuadrador</h3>
    </div>
    <!-- ******************** resumen de bultos -->
    <div class="col-md-12">
      <download-excel
        v-if="flag_filtradoDePlacaActivo"
        class="outline-btn-excel pull-right"
        style="cursor: pointer; margin-bottom:7px;"
        :fields="columnasEXCLsector"
        :before-generate="
          () => {
            return startDownload();
          }
        "
        :fetch="
          () => {
            return fnGenerarExcelReportesAcumulado();
          }
        "
        :type="dataExportType"
        :before-finish="finishDownload"
        :name="
          'Multiples reportes de bultos escaneados (' +
            flag_nombre_piloto_filtro +
            ') ' +
            dataExportType
        "
        >Descargar detalle de bultos de reportes filtrados</download-excel
      >
    </div>
    <div class="col-md-12">
      <v-server-table
        :columns="columns"
        :options="optionsVTable"
        ref="tblReporteDeBultosValidados"
      >
        <div slot-scope="props" slot="descargar">
          <download-excel
            class="btn btn-sm"
            style="cursor: pointer"
            :fields="columnasEXCLsector"
            :before-generate="
              () => {
                return startDownload(props.row.id);
              }
            "
            :fetch="
              () => {
                return fnGenerarExcel(props.row.id);
              }
            "
            :type="dataExportType"
            :before-finish="finishDownload"
            :name="'Bultos del reporte con id ' + props.row.id + dataExportType"
            >Excel</download-excel
          >
        </div>
        <!-- ------ -->
        <span slot="ultimo_escaneado_fecha" slot-scope="props">
          {{
            props.row.ultimo_escaneado_fecha
              | moment("utc", "DD/MM/YYYY HH:mm:ss a")
          }}
        </span>
        <!-- ---- -->
        <span
          style="cursor:pointer;"
          data-toggle="modal"
          data-target="#modalBultosEscaneados"
          slot="bultos_escaneados"
          slot-scope="props"
          @click="fnCargarBultosEscaneados(props.row.id)"
        >
          {{ props.row.bultos_escaneados }}
        </span>
        <!-- ---- -->
        <span
          style="cursor:pointer;"
          data-toggle="modal"
          data-target="#modalBultosPendientes"
          slot="bultos_pendientes"
          slot-scope="props"
          @click="fnMostrarBultosSinValidar(props.row.id)"
        >
          {{ props.row.bultos_pendientes }}
        </span>
        <div class="progress" slot-scope="props" slot="porcentaje">
          <div
            :class="'progress-bar ' + getColor(props.row.porcentaje)"
            role="progressbar"
            :style="'width:' + props.row.porcentaje + '%'"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <small
            :class="
              'justify-content-center d-flex position-absolute progress-value ' +
                getTextColor(props.row.porcentaje)
            "
            >{{ props.row.porcentaje }}%</small
          >
        </div>
      </v-server-table>
    </div>
    <!-- ******************** TABLA DE USUARIOS -->
    <modalBultosEscaneados
      :escaneados_reportebultosvalidados_id="
        escaneados_reportebultosvalidados_id
      "
    ></modalBultosEscaneados>
    <modalBultosPendientes
      :pendientes_reportebultosvalidados_id="
        pendientes_reportebultosvalidados_id
      "
    ></modalBultosPendientes>
  </div>
</template>
<script>
import axios from "axios";
import { mapState, mapGetters, mapActions } from "vuex";
import JsonExcel from "vue-json-excel";
import modalBultosEscaneados from "@/pages/DashboardEntregas/bultos/modalBultosEscaneados.vue";
import modalBultosPendientes from "@/pages/DashboardEntregas/bultos/modalBultosPendientes.vue";
export default {
  components: {
    modalBultosEscaneados,
    modalBultosPendientes,
    downloadExcel: JsonExcel
  },
  data() {
    return {
      // exportar varios reportes a la vez
      flag_nombre_piloto_filtro: "",
      flag_filtradoDePlacaActivo: false,
      flag_exportarMultipleReportsIdArray: [],
      //
      dataExportType: "xls",

      columnasEXCLsector: {
        Cuadrador: "cuadrador",
        Sector: "DELIVERY_CLIENT_REGION",
        Ruta: "ROUTE_ID",
        Pedido: "ERP_DOC",
        "Box Id": "BOX_ID",
        tipo: "tipobulto",
        Estado: "estado",
        "Tipo validacion": "tipo_validacion",
        fecha_validacion: "fecha_validacion",
        Piloto: "piloto",
        Placa: "placa"
      },
      pendientes_reportebultosvalidados_id: null,
      escaneados_reportebultosvalidados_id: null,
      modalEditarModulosModulosGlobal: [],
      modalEditarModulosModulosDeUsuario: [],
      padreCodigoUsuario: "",
      ListadoDeRoles: [],
      ListadoDeEmpresasTransportistas: [],
      varNuevoUsuario: {
        cuadrador: "",
        id_rol: "",
        DELIVERY_COMPANY_ID: "",
        DELIVERY_COMPANY_NAME: "",
        password: ""
      },
      columns: [
        "cuadrador",
        "piloto_placa",
        "DELIVERY_CLIENT_REGION",
        "bultos_total",
        "bultos_escaneados",
        "bultos_pendientes",
        "ultimo_escaneado",
        "ultimo_escaneado_fecha",
        "tipo_de_validacion",
        "descargar",
        "porcentaje"
      ],
      optionsVTable: {
        orderBy: {
          column: "ultimo_escaneado_fecha",
          ascending: false
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down"
        },
        filterByColumn: true,
        perPage: 10,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "Ultimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "No se encontraron resultados...",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas"
        },
        dateFormat: "DD-MM-YYYY HH:mm",
        dateColumns: ["ultimo_escaneado_fecha"],
        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD/MM/YYYY",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Borrar filtro",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre"
            ],
            firstDay: 1
          },
          showDropdowns: true,
          autoUpdateInput: true
        },
        pagination: { chunk: 10, dropdown: false },
        filterable: [
          "cuadrador",
          "piloto_placa",
          "tipo_de_validacion",
          "DELIVERY_CLIENT_REGION",
          "ultimo_escaneado_fecha"
        ],
        headings: {
          piloto_placa: "Placa del piloto",
          tipo_de_validacion: "Tipo de carga",
          cuadrador: "Cuadrador",
          DELIVERY_CLIENT_REGION: "Sector",
          DELIVERY_COMPANY_NAME: "Empresa",
          edit: "Editar",
          abaja: "Dar de baja"
        },
        requestAdapter(data) {
          let limit = `"$limit":${this.perPage}`;
          let skip = `"$skip":${(data.page - 1) * this.perPage}`;
          let sort = `"$sort[${data.orderBy}]":${data.ascending}`;
          let search = ``;
          search += `, "cuadrador[$ne]": "NULL"`;
          if (data.query) {
            if (data.query["DELIVERY_CLIENT_REGION"]) {
              search += `, "DELIVERY_CLIENT_REGION[$like]": "%${data.query["DELIVERY_CLIENT_REGION"]}%"`;
            }
            if (data.query["piloto_placa"]) {
              search += `, "piloto_placa[$like]": "%${data.query["piloto_placa"]}%"`;
            }
            if (data.query["cuadrador"]) {
              search += `, "cuadrador[$like]": "%${data.query["cuadrador"]}%"`;
            }
            if (data.query["tipo_de_validacion"]) {
              search += `, "tipo_de_validacion[$like]": "%${data.query["tipo_de_validacion"]}%"`;
            }
            // if (data.query["id_rol"]) {
            //   search += `, "id_rol": ${data.query["id_rol"]}`;
            // }
            if (data.query["ultimo_escaneado_fecha"]) {
              search += `, "ultimo_escaneado_fecha[$gte]": "${data.query.ultimo_escaneado_fecha.start}"`;
              search += `, "ultimo_escaneado_fecha[$lte]": "${data.query.ultimo_escaneado_fecha.end}"`;
            }
          }

          return JSON.parse(`{${limit}, ${skip}, ${sort} ${search}}`);
        },
        requestFunction(data) {
          let url = `${process.env.VUE_APP_API_URL}/reportebultosvalidados`;
          return axios.get(url, {
            params: data,
            headers: {
              Authorization:
                "Bearer  " + window.localStorage.getItem("feathers-jwt")
            }
          });
        },
        responseAdapter(resp) {
          // console.log("reporte de bultos validados ");
          // console.log(resp.data.data);
          this.$parent.flag_exportarMultipleReportsIdArray = [];
          resp.data.data.forEach(reporte => {
            this.$parent.flag_exportarMultipleReportsIdArray.push(reporte.id);
          });
          return {
            data: resp.data.data,
            count: resp.data.total
          };
        }
      }
    };
  },
  methods: {
    ...mapActions("Bultosporreportedevalidacion", {
      findBultosporreporte: "find"
    }),
    ...mapActions("Pedidos", {
      getPedido: "get",
      findPedidos: "find"
    }),
    ...mapActions("PedidosDetalle", {
      getPedidoDetalle: "get",
      findPedidoDetalles: "find"
    }),
    fnMostrarBultosSinValidar(reporteId) {
      this.pendientes_reportebultosvalidados_id = reporteId;
      // console.log(this.pendientes_reportebultosvalidados_id);
    },
    fnCargarBultosEscaneados(reporteId) {
      this.escaneados_reportebultosvalidados_id = reporteId;
      // console.log(this.escaneados_reportebultosvalidados_id);
    },
    getTextColor(value) {
      if (value == 100) {
        return "text-pg-green";
      } else if (value >= 80 && value <= 99.99) {
        return "text-pg-yellow";
      } else if (value >= 60 && value <= 79.99) {
        return "text-pg-orange";
      } else if (value >= 0.01 && value <= 59.99) {
        return "text-pg-red";
      }
    },
    getColor(value) {
      if (value == 100) {
        return "bg-green";
      } else if (value >= 80 && value <= 99.99) {
        return "bg-yellow";
      } else if (value >= 60 && value <= 79.99) {
        return "bg-orange";
      } else if (value >= 0.01 && value <= 59.99) {
        return "bg-red";
      }
    },
    async fnGenerarExcel(reporteid) {
      const bultos = await this.findBultosporreporte({
        query: {
          reporteid: reporteid
        }
      }).then(result => {
        return result;
      });

      return bultos;
    },
    async fnGenerarExcelReportesAcumulado() {
      // console.log(
      //   "sending these reports: ",
      //   this.flag_exportarMultipleReportsIdArray
      // );
      const bultos = await this.findBultosporreporte({
        query: {
          reporteid: this.flag_exportarMultipleReportsIdArray,
          multi: true
        }
      }).then(result => {
        return result;
      });

      return bultos;
    },
    startDownload() {},
    finishDownload() {}
  },
  mounted() {
    /**/

    const { Usuarios } = this.$FeathersVuex.api;
    Usuarios.on("patched", result => {
      this.$refs.tblReporteDeBultosValidados.refresh();
    });
    /**/
    /** escuchar filtro de placa, para poder expotar el de talle de varios reportes */
    this.$watch(
      () => {
        return this.$refs.tblReporteDeBultosValidados.query.piloto_placa;
      },
      val => {
        this.flag_nombre_piloto_filtro = val;
        if (val != "") {
          this.flag_filtradoDePlacaActivo = true;
        } else {
          this.flag_filtradoDePlacaActivo = false;
        }
      }
    );
    /** escuchar filtro de placa, para poder expotar el de talle de varios reportes */
    this.$watch(
      () => {
        return this.$refs.tblReporteDeBultosValidados.query
          .ultimo_escaneado_fecha;
      },
      val => {
        if (val != "") {
          this.flag_filtradoDePlacaActivo = true;
        } else {
          this.flag_filtradoDePlacaActivo = false;
        }
      }
    );
  },
  created() {}
};
</script>
<style scoped>
.bg-green {
  background-color: #2e7d32 !important;
  color: white !important;
}
.bg-yellow {
  background-color: #eeff41 !important;
  color: black !important;
}
.bg-orange {
  background-color: #ff9800 !important;
  color: white !important;
}
.bg-red {
  background-color: #b71c1c !important;
  color: white !important;
}
.text-pg-green {
  color: white !important;
}
.text-pg-yellow {
  color: black !important;
}
.text-pg-orange {
  color: white !important;
}
.text-pg-red {
  color: white !important;
}
.progress {
  height: 13px !important;
}
.progress-value {
  width: 5.35%;

  font-size: 10px;
  font-weight: 600;
}
.outline-btn-excel {
  border: 1px solid #1f6e43;
  border-radius: 15px;
  background-color: white;
  color: #1f6e43;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
}
.outline-btn-excel:hover {
  border: 1px solid #1f6e43;
  border-radius: 15px;
  background-color: #739b85;
  color: #ffffff;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
}
</style>

<template>
  <div class="row">
    <div class="col-md-6">
      <h3>Reporte de bultos escaneados por sector</h3>
    </div>
    <!-- /**  */ -->
    <div class="col-md-3">
      <div class="form-group">
        <label for="">Ingrese sector: </label>
        <input
          type="text"
          class="form-control"
          v-model="excelsector"
          aria-describedby="helpId"
          placeholder=""
        />
        <small id="helpId" class="form-text text-muted"
          >se buscarán todos los bultos en la campaña
          {{ campaniaSelected }}</small
        >
      </div>
    </div>
    <div class="col-md-1 ">
      <download-excel
        class="btn btn-sm"
        style="cursor: pointer; margin-top:31px"
        :fields="columnasEXCLsector"
        :before-generate="
          () => {
            return startDownload();
          }
        "
        :fetch="
          () => {
            return fnGenerarExcelPorSector(excelsector);
          }
        "
        :type="dataExportType"
        :before-finish="finishDownload"
        :name="'Bultos del sector ' + excelsector + dataExportType"
        >Excel</download-excel
      >
    </div>
    <!-- /**  */ -->
    <div class="col-md-4">
      <label for="">Consultando pedidos de la campaña:</label>
      <select
        class="form-control"
        @change="fnSelecionarCampania"
        name="campania"
      >
        <option
          v-for="(item, index) in campanias"
          :selected="index == 0"
          :key="index"
          :value="item.campania"
        >
          Campaña {{ item.campania }}
        </option>
      </select>
    </div>
    <!-- ******************** resumen de bultos -->
    <div class="col-md-12">
      <v-server-table
        :columns="columns"
        :options="optionsVTable"
        ref="tblPedidosBultosSector"
      >
        <div slot-scope="props" slot="descargar">
          <download-excel
            class="btn btn-sm"
            style="cursor: pointer"
            :fields="columnasEXCLsector"
            :before-generate="
              () => {
                return startDownload();
              }
            "
            :fetch="
              () => {
                return fnGenerarExcel(props.row.ROUTE_ID);
              }
            "
            :type="dataExportType"
            :before-finish="finishDownload"
            :name="'Bultos de la ruta ' + props.row.ROUTE_ID + dataExportType"
            >Excel</download-excel
          >
        </div>
        <span slot="ultimo_escaneado_fecha" slot-scope="props">
          {{
            props.row.ultimo_escaneado_fecha
              | moment("utc", "DD/MM/YYYY HH:mm:ss a")
          }}
        </span>
        <!-- ---- -->
        <span
          style="cursor:pointer;"
          data-toggle="modal"
          data-target="#modalBultosPendientes"
          slot="escaneados"
          slot-scope="props"
          @click="fnMostrarBultosSinValidar('Comple', props.row.ROUTE_ID)"
        >
          {{ props.row.escaneados }}
        </span>
        <!-- ---- -->
        <span
          style="cursor:pointer;"
          data-toggle="modal"
          data-target="#modalBultosPendientes"
          slot="pendientes"
          slot-scope="props"
          @click="fnMostrarBultosSinValidar('Sin', props.row.ROUTE_ID)"
        >
          {{ props.row.pendientes }}
        </span>
        <div class="progress" slot-scope="props" slot="porcentaje">
          <div
            :class="'progress-bar ' + getColor(props.row.porcentaje)"
            role="progressbar"
            :style="'width:' + props.row.porcentaje + '%'"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <small
            :class="
              'justify-content-center d-flex position-absolute progress-value ' +
                getTextColor(props.row.porcentaje)
            "
          >
            {{ ((props.row.escaneados / props.row.total) * 100).toFixed(0) }}%
          </small>
        </div>
      </v-server-table>
    </div>
    <!-- ******************** TABLA DE USUARIOS -->

    <modalBultosPendientes
      :fk_estadoDePedido="fk_estadoDePedido"
      :fk_ROUTE="fk_ROUTE"
      :campaniaSelected="campaniaSelected"
    ></modalBultosPendientes>
  </div>
</template>
<script>
import axios from "axios";
import { mapState, mapGetters, mapActions } from "vuex";
import JsonExcel from "vue-json-excel";
import modalBultosPendientes from "@/pages/DashboardEntregas/bultos/modalBultosPendientesPorSector.vue";
export default {
  components: {
    modalBultosPendientes,
    downloadExcel: JsonExcel
  },
  data() {
    return {
      excelsector: null,
      dataExportType: "xls",
      columnasEXCLsector: {
        Sector: "DELIVERY_CLIENT_REGION",
        Ruta: "ROUTE_ID",
        Pedido: "ERP_DOC",
        "Box Id": "BOX_ID",
        tipo: "tipobulto",
        Estado: "estado",
        "Tipo validacion": "tipo_validacion",
        fecha_validacion: "fecha_validacion",
        Piloto: "piloto",
        Placa: "placa"
      },
      //
      campaniaSelected: "",
      campanias: [],
      fk_estadoDePedido: null,
      fk_ROUTE: null,
      escaneados_reportebultosvalidados_id: null,
      modalEditarModulosModulosGlobal: [],
      modalEditarModulosModulosDeUsuario: [],
      padreCodigoUsuario: "",
      ListadoDeRoles: [],
      ListadoDeEmpresasTransportistas: [],

      columns: [
        "DELIVERY_CLIENT_REGION",
        "ROUTE_ID",
        "total",
        "escaneados",
        "pendientes",
        "descargar",
        "porcentaje"
      ],
      optionsVTable: {
        campania: null,
        orderBy: {
          column: "DELIVERY_CLIENT_REGION",
          ascending: true
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down"
        },
        filterByColumn: true,
        perPage: 10,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "Ultimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "Cargando...",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas"
        },
        dateFormat: "DD-MM-YYYY HH:mm",
        dateColumns: ["ultimo_escaneado_fecha"],
        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD/MM/YYYY",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre"
            ],
            firstDay: 1
          },
          showDropdowns: true,
          autoUpdateInput: true
        },
        pagination: { chunk: 10, dropdown: false },
        filterable: ["DELIVERY_CLIENT_REGION", "ROUTE_ID"],
        headings: {
          tipo_de_validacion: "Tipo de carga",
          total: "Pedidos total",
          cuadrador: "Cuadrador",
          escaneados: "Pedidos validados",
          pendientes: "Pedidos con bultos pendientes",
          DELIVERY_CLIENT_REGION: "Sector",
          DELIVERY_COMPANY_NAME: "Empresa",
          edit: "Editar",
          abaja: "Dar de baja"
        },
        requestAdapter(data) {
          let limit = `"$limit":${this.perPage}`;
          let skip = `"$skip":${(data.page - 1) * this.perPage}`;
          let sort = `"$sort[${data.orderBy}]":${data.ascending}`;
          let search = ``;
          search += `, "CAMPAING_ID": "${this.campania}"`;

          if (data.query) {
            if (data.query["DELIVERY_CLIENT_REGION"]) {
              search += `, "DELIVERY_CLIENT_REGION": "${data.query["DELIVERY_CLIENT_REGION"]}"`;
            }
            if (data.query["ROUTE_ID"]) {
              search += `, "ROUTE_ID": "${data.query["ROUTE_ID"]}"`;
            }
            // if (data.query["cuadrador"]) {
            //   search += `, "cuadrador[$like]": "%${data.query["cuadrador"]}%"`;
            // }
            // if (data.query["tipo_de_validacion"]) {
            //   search += `, "tipo_de_validacion[$like]": "%${data.query["tipo_de_validacion"]}%"`;
            // }
            // if (data.query["id_rol"]) {
            //   search += `, "id_rol": ${data.query["id_rol"]}`;
            // }
            // if (data.query["updatedAt"]) {
            //   search += `, "updatedAt[$gte]": "${data.query.updatedAt.start}"`;
            //   search += `, "updatedAt[$lte]": "${data.query.updatedAt.end}"`;
            // }
          }

          return JSON.parse(`{${limit}, ${skip}, ${sort} ${search}}`);
        },
        requestFunction(data) {
          let url = `${process.env.VUE_APP_API_URL}/resumenbultoporsector`;
          return axios.get(url, {
            params: data,
            headers: {
              Authorization:
                "Bearer  " + window.localStorage.getItem("feathers-jwt")
            }
          });
        },
        responseAdapter(resp) {
          return {
            data: resp.data.data,
            count: resp.data.total
          };
        }
      }
    };
  },
  methods: {
    ...mapActions("Campanias", {
      findCampanias: "find"
    }),
    ...mapActions("Pedidos", {
      getPedido: "get",
      findPedidos: "find"
    }),
    ...mapActions("PedidosDetalle", {
      getPedidoDetalle: "get",
      findPedidoDetalles: "find"
    }),
    ...mapActions("Bultosporruta", {
      findBultosporruta: "find"
    }),
    fnSelecionarCampania() {
      this.campaniaSelected = event.target.value;
      this.optionsVTable.campania = event.target.value;
      this.$refs.tblPedidosBultosSector.refresh();
    },
    fnMostrarBultosSinValidar(status, route) {
      this.fk_estadoDePedido = status;
      this.fk_ROUTE = route;
    },
    fnCargarBultosEscaneados(reporteId) {
      this.escaneados_reportebultosvalidados_id = reporteId;
    },
    getTextColor(value) {
      if (value == 100) {
        return "text-pg-green";
      } else if (value >= 80 && value <= 99.99) {
        return "text-pg-yellow";
      } else if (value >= 60 && value <= 79.99) {
        return "text-pg-orange";
      } else if (value >= 0.01 && value <= 59.99) {
        return "text-pg-red";
      }
    },
    getColor(value) {
      if (value == 100) {
        return "bg-green";
      } else if (value >= 80 && value <= 99.99) {
        return "bg-yellow";
      } else if (value >= 60 && value <= 79.99) {
        return "bg-orange";
      } else if (value >= 0.01 && value <= 59.99) {
        return "bg-red";
      }
    },
    async fnGenerarExcelPorSector(SECTOR) {
      let sectorsearch = 0;
      if (SECTOR.length == 1) {
        sectorsearch = 0 + SECTOR;
      } else {
        sectorsearch = SECTOR;
      }
      const bultos = await this.findBultosporruta({
        query: {
          CAMPAING_ID: this.campaniaSelected,
          DELIVERY_CLIENT_REGION: sectorsearch
        }
      }).then(result => {
        console.log("resultado");
        console.log(result);
        return result;
      });

      return bultos;
    },
    async fnGenerarExcel(rutaid) {
      const bultos = await this.findBultosporruta({
        query: {
          CAMPAING_ID: this.campaniaSelected,
          ROUTE_ID: rutaid
        }
      }).then(result => {
        return result;
      });

      return bultos;
    },
    startDownload() {},
    finishDownload() {}
  },
  mounted() {
    this.findCampanias({ query: { limit: 3 } }).then(result => {
      this.campanias = result;
      this.optionsVTable.campania = this.campanias[0].campania;
      this.campaniaSelected = this.campanias[0].campania;
      this.$refs.tblPedidosBultosSector.refresh();
    });
    /**/

    /**/
  },
  created() {}
};
</script>
<style scoped>
.bg-green {
  background-color: #2e7d32 !important;
  color: white !important;
}
.bg-yellow {
  background-color: #eeff41 !important;
  color: black !important;
}
.bg-orange {
  background-color: #ff9800 !important;
  color: white !important;
}
.bg-red {
  background-color: #b71c1c !important;
  color: white !important;
}
.text-pg-green {
  color: white !important;
}
.text-pg-yellow {
  color: black !important;
}
.text-pg-orange {
  color: white !important;
}
.text-pg-red {
  color: white !important;
}
.progress {
  height: 13px !important;
}
.progress-value {
  width: 7.35%;

  font-size: 10px;
  font-weight: 600;
}
</style>
